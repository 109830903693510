/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unstable-nested-components */
import React, {
  useContext, useState, useEffect
} from 'react';
import {isMobile} from 'react-device-detect';
import {
  Card,
  Steps,
  Typography,
  Button,
  ConfigProvider,
  Image,
  Modal,
  Upload,
  message,
  Input,
  Tag,
  Drawer,
  Checkbox,
  Space
} from 'antd';
import {
  DownloadOutlined,
  ZoomOutOutlined,
  ZoomInOutlined,
  UploadOutlined,
  PaperClipOutlined
  // EditOutlined
} from '@ant-design/icons';
import style from './styles/bill.module.scss';
import './styles/style.css';
import api from '@modules/api';
import UserContext from '@context/UserContext';
import dayjs from 'dayjs';
import {numberFormat, formatDate} from '@modules/common';

const {Title} = Typography;

export function Bill({
  bill,
  selectMode = false,
  selected = false,
  onSelect,
  forPayment = false,
  // editable,
  // editHandler,
  editRequestCallback,
  removeBill,
  showAuthor = false,
  toggleLoading
}) {
  const [
    isOpen,
    setIsOpen
  ] = useState(false);
  const [
    editRequestModal,
    setEditRequestModal
  ] = useState(false);
  const [
    editRequestPending,
    setEditRequestPending
  ] = useState(false);
  const [
    drawerOpen,
    setDrawerOpen
  ] = useState(false);
  const [
    paymentCard,
    setPaymentCard
  ] = useState(null);
  const [
    typeBtn,
    setTypeBtn
  ] = useState(null);
  const [
    commitEnd,
    setCommitEnd
  ] = useState('');
  const [
    size,
    setSize
  ] = useState(window.innerWidth <= 900 ? 'mobile' : 'desktop');
  const [
    editReason,
    setEditReason
  ] = useState('');
  const userSelf = useContext(UserContext);
  const toggleIsOpen = () => setIsOpen((prev) => !prev);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 900) {
        setSize('mobile');
      } else {
        setSize('desktop');
      }
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const changeStatusBill = async (type) => {
    toggleLoading();
    await api('bill/change_status', {
      method: 'post',
      body: {
        type,
        paymentCard,
        billId: bill.id,
        ...(commitEnd ? {commitEnd} : null)
      }
    });
    removeBill(bill.id);
    toggleLoading();
  };

  const handleEditRequest = async () => {
    setEditRequestPending(true);
    const response = await api('bill/for-edit', {
      method: 'PUT',
      headers: {'Content-Type': 'application/json'},
      body: {
        editReason,
        id: bill.id
      }
    });
    setEditRequestPending(false);
    setEditRequestModal(false);
    setEditReason(null);
    if (response.status === 200) {
      editRequestCallback(bill.id);
    }
  };

  function Buttons() {
    const eventBtn = async (type) => {
      const checkOrganization = bill.organizationType === 'organization' && bill.organizationId === 2;
      if ((checkForPayment() && checkOrganization) || type === 'cancel') {
        setTypeBtn(type);
        setCommitEnd(null);
        toggleIsOpen();
      } else {
        await changeStatusBill(type);
      }
    };

    return (
      <div
        style={{
          display: 'flex',
          height: '100%',
          justifyContent: 'flex-end',
          gap: '12px',
          alignItems: 'center'
        }}
      >
        {/* <Button onClick={() => setEditRequestModal(true)} icon={<EditOutlined />} /> */}
        <Button danger onClick={() => eventBtn('cancel')}>
          {forPayment && checkForPayment() ? 'Отклонено' : 'Отменить'}
        </Button>
        <ConfigProvider
          theme={{
            components: {
              Button: {
                colorPrimary: '#35C14F',
                defaultBorderColor: '#35C14F',
                colorText: '#35C14F',
                algorithm: true
              }
            }
          }}
        >
          <Button color="primary" onClick={() => eventBtn('confirm')}>
            {forPayment && checkForPayment() ? 'Оплачено' : 'Согласовать'}
          </Button>
        </ConfigProvider>
      </div>
    );
  }

  const NormalizeItems = () => bill.confirmList.map((value, i) => {
    const item = {};
    const {
      status,
      user
    } = value;
    const {
      f,
      o
    } = user;
    switch (status) {
    case 'confirm':
      item.status = 'finish';
      item.title = (
        <Typography.Paragraph
          ellipsis={{rows: 2}}
          className={style.StepTitle}
        >
          {bill.confirmList.length - 1 === i ? 'Оплачен' : 'Согласован'}
          {' '}
          <br/>
          <div className={style.StepDate}>{formatDate(value.eventDate)}</div>
        </Typography.Paragraph>
      );
      break;
    case 'new':
      item.status = 'process';
      item.title = (
        <Typography.Paragraph
          ellipsis={{rows: 2}}
          className={style.StepTitle}
        >
          {forPayment ? 'В процессе' : i === bill.confirmList.length - 1 ? 'Ожидание' : 'На согласовании'}
        </Typography.Paragraph>
      );
      break;
    case 'cancel':
      item.status = 'error';
      item.title = (
        <Typography.Paragraph
          ellipsis={{rows: 3}}
          className={style.StepTitle}
        >
          Отклонен
          {' '}
          <br/>
          <div
            className={style.StepDate}
          >
            {formatDate(value.eventDate)}
          </div>
        </Typography.Paragraph>
      );
      break;
    default:
      item.status = 'wait';
      item.title = (
        <Typography.Paragraph
          ellipsis={{rows: 2}}
          className={style.StepTitle}
        >
          Ждет
        </Typography.Paragraph>
      );
      break;
    }
    item.description = (
      <Typography.Paragraph
        ellipsis={{rows: 3}}
        className={style.StepDescription}
      >
        {`${f} ${user.i} ${o}`}
      </Typography.Paragraph>
    );

    return item;
  });

  const objectList = (sizeLocal) => (sizeLocal !== 'mobile'
    ? [
      {
        key: 'expenses',
        title: 'Статья расходов'
      },
      {
        key: 'howToPay',
        title: 'За что платим'
      },
      {
        key: 'sum',
        title: 'Сумма счета'
      },
      {
        key: 'firm',
        title: 'Фирма'
      },
      {
        key: 'organization',
        title: 'Филиал'
      },
      {
        key: 'comment',
        title: 'Комментарий'
      },
      {
        key: 'billFile',
        title: 'Копия счета'
      },
      ...(showAuthor
        ? [
          {
            key: 'author',
            title: 'Автор'
          }
        ]
        : []),
      ...(bill.paymentCard?.length
        ? [
          {
            key: 'paymentCard',
            title: 'Платежка'
          }
        ]
        : []),
      ...(bill.status === 'deviations'
        ? [
          {
            key: 'commentEnd',
            title: 'Причина отклонения'
          }
        ]
        : []),
      ...(bill.editReason
        ? [
          {
            key: 'editReason',
            title: 'Причина редактирования'
          }
        ]
        : [])
    ]
    : [
      {
        key: 'expenses',
        title: 'Статья расходов'
      },
      {
        key: 'howToPay',
        title: 'За что платим'
      },
      {
        key: 'billFile',
        title: 'Копия счета'
      }
    ]);

  const filesComponent = (files, key) => (
    <div
      style={{
        display: 'flex',
        gap: '5px',
        flexWrap: 'wrap'
      }}
      key={key}
    >
      {files.map(({
        name,
        url
      }) => {
        const fileRender = url.toLowerCase().endsWith('.pdf')
          ? {
            imageRender: () => (
              <iframe
                title="pdf"
                src={url}
                style={{
                  width: 'calc(100vw)',
                  height: '100%'
                }}
              />
            )
          }
          : {
            toolbarRender: (_, {
              transform: {scale},
              actions: {
                onZoomOut,
                onZoomIn
              }
            }) => (
              <div style={{
                display: 'flex',
                alignItems: 'center',
                gap: '15px'
              }}
              >
                <a style={{color: 'white'}} download href={`/${url}`}>
                  <Button icon={<DownloadOutlined/>}/>
                </a>
                <Button
                  icon={<ZoomOutOutlined/>}
                  disabled={scale === 1}
                  onClick={onZoomOut}
                />
                <Button
                  icon={<ZoomInOutlined/>}
                  disabled={scale === 50}
                  onClick={onZoomIn}
                />
              </div>
            )
          };
        return (
          <FileComponent
            name={name}
            fileRender={fileRender}
            url={url}
            key={url}
          />
        );
      })}
    </div>
  );

  function FileComponent({
    name,
    fileRender,
    url
  }) {
    const [
      open,
      setOpen
    ] = useState(false);
    const fileName = () => {
      const index = name.indexOf('-');
      return name.slice(index + 1);
    };
    return (
      <>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          gap: '5px',
          cursor: 'pointer',
          color: '#0486FF'
        }}
        >
          <a
            style={{wordBreak: 'break-all'}}
            href={url}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...(isMobile || url.endsWith('.xls') || url.endsWith('.xlsx')
              ? {}
              : {
                onClick: (e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setOpen(true);
                }
              })}
          >
            <PaperClipOutlined/>
            {fileName()}
          </a>
        </div>
        {open && !isMobile ? (
          <Image
            preview={{
              visible: open,
              destroyOnClose: true,
              onVisibleChange: (value) => {
                setOpen(value);
              },
              toolbarRender: () => null,
              ...fileRender
            }}
            key={url}
            src={url.endsWith('.pdf') ? '/pdf.svg' : url}
            style={{
              objectFit: 'contain',
              display: 'none'
            }}
          />
        ) : (
          ''
        )}
      </>
    );
  }

  const getValueBill = (key) => {
    switch (key) {
    case 'expenses':
      return `${bill.expenses.number} ${bill.expenses.name}`;
    case 'howToPay':
      return <div style={{fontWeight: 500}}>{bill.howToPay}</div>;
    case 'billFile':
      return filesComponent(bill.files);
    case 'paymentCard':
      return filesComponent(bill.paymentCard || []);
    case 'firm':
      return bill.firm.name;
    case 'editReason':
      return bill.editReason;
    case 'sum':
      return `${numberFormat(bill.sum)} RUB`;
    case 'organization':
      return bill.organization.title ? bill.organization.title : bill.organization.name;
    case 'comment':
      return bill.comment;
    case 'commentEnd':
      return bill.commentEnd;
    default:
      return (
        <div style={{
          display: 'flex',
          alignItems: 'center',
          gap: '5px',
          flexWrap: 'wrap'
        }}
        >
          {bill.author.f}
          {' '}
          {bill.author.i}
          {' '}
          {bill.author.o || ''}
          {' '}
          {bill.author.email
            ? (
              <div>
                (
                {bill.author.email}
                )
              </div>
            ) : ''}
        </div>
      );
    }
  };

  const checkForPayment = () => !!bill.billConfirmations[bill.billConfirmations.length - 1].status;

  const beforeUpload = (file) => {
    const mimeType = [
      'image/jpeg',
      'image/png',
      'application/pdf',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ];
    const isIMG = mimeType.some((type) => file.type === type);
    if (!isIMG) {
      message.error(`${file.name} не поддерживаемый формат файла`, 5);
    }
    return isIMG || Upload.LIST_IGNORE;
  };

  const handleChange = (info) => {
    info.fileList.forEach((file) => {
      if (file.response) {
        setPaymentCard(file.response.file.filename);
        // eslint-disable-next-line no-param-reassign
        file.url = `api/upload/${file.response.file.filename}`;
      }
    });
  };

  const getBage = () => {
    const {
      status,
      forEdit
    } = bill;
    if (forEdit) {
      return (
        <Tag color="grey" className={style.Tag}>
          На редактировании
        </Tag>
      );
    }
    switch (status) {
    case 'deviations':
      return (
        <Tag color="red" className={style.Tag}>
          Отклонён
        </Tag>
      );
    case 'paid':
      return (
        <Tag color="green" className={style.Tag}>
          Оплачен
        </Tag>
      );
    case 'awaitingPayment':
      return (
        <Tag color="orange" className={style.Tag}>
          На рассмотрении
        </Tag>
      );
    case 'onСonfirmation':
      return (
        <Tag color="orange" className={style.Tag}>
          На рассмотрении
        </Tag>
      );
    default:
      return '';
    }
  };

  return (
    <Card size="small" style={{marginBottom: 20}} className={style.BillCard}>
      <div className={style.CardHeader}>
        <div style={{
          display: 'flex',
          gap: isMobile ? '7px' : '15px'
        }}
        >
          {selectMode ? (
            <Checkbox
              checked={selected}
              onChange={() => {
                onSelect(bill.id);
              }}
            />
          ) : (
            ''
          )}
          {/* {editable && bill.forEdit ? ( */}
          {/*  <Button {...(isMobile && {size: "small"})} type="primary" onClick={() => editHandler(bill)} icon={<EditOutlined />} /> */}
          {/* ) : ( */}
          {/*  "" */}
          {/* )} */}

          <Typography className={style.CardTitle}>
            <div>
              Согласование №
              {bill.id}
            </div>
            <div
              className={style.CardTitleFrom}
            >
              от
              {' '}
              {dayjs(bill.createdAt).format('DD.MM.YY HH:mm')}
            </div>
          </Typography>
        </div>
        {getBage()}
      </div>
      <div className={style.CardRows}>
        <div>
          <Steps
            className={style.Steps}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...(size === 'mobile'
              ? (() => {
                let initial = 0;
                const item = NormalizeItems().find((el, index, arr) => {
                  if (el.status === 'process' || el.status === 'error' || arr.length - 1 === index) {
                    initial = index;
                    return true;
                  }
                  return false;
                });
                return {
                  items: [item],
                  initial
                };
              })()
              : {items: NormalizeItems()})}
            direction="vertical"
            labelPlacement="horizontal"
            size="default"
          />
        </div>
        <div className={style.List}>
          {objectList(size).map((obj) => (
            <>
              <Title level={5} className={style.ListTitle}>
                {obj.title}
                :
                {' '}
              </Title>
              <div>{getValueBill(obj.key)}</div>
            </>
          ))}
        </div>
      </div>
      {size === 'mobile' && (
        <div style={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}
        >
          <Button
            className={style.DetailsButton}
            onClick={() => setDrawerOpen(true)}
            color="primary"
            type="link"
          >
            Подробней
          </Button>
        </div>
      )}
      {forPayment && bill.billConfirmations.findLast((val) => val.status === 'new')?.user.id === userSelf.id
        ? <Buttons/> : ''}
      <Modal
        open={isOpen}
        width={1000}
        title={typeBtn === 'confirm' ? 'Добавление платежки' : 'Отклонение счета'}
        okText={typeBtn === 'confirm' ? 'Оплачено' : 'Отклонено'}
        cancelText="Закрыть"
        onCancel={toggleIsOpen}
        onOk={() => {
          toggleIsOpen();
          changeStatusBill(typeBtn);
        }}
        okButtonProps={{
          disabled: typeBtn === 'confirm' ? false : !commitEnd?.trim(),
          style: {
            backgroundColor: typeBtn === 'confirm' ? 'rgba(23, 146, 23, 0.87)' : 'rgba(219, 29, 29, 0.87)',
            color: 'white'
          }
        }}
        closable={false}
      >
        <div>
          <Typography
            className={style.LabelRequired}
          >
            {typeBtn === 'confirm' ? 'Платежка' : 'Причина отклонения'}
            :
          </Typography>
          <div>
            {typeBtn === 'confirm' ? (
              <Upload
                action="api/upload"
                onRemove={() => setPaymentCard(null)}
                listType="picture"
                beforeUpload={beforeUpload}
                onChange={handleChange}
              >
                {!paymentCard && (
                  <Button type="primary" icon={<UploadOutlined/>}>
                    Добавить
                  </Button>
                )}
              </Upload>
            ) : (
              <Input
                placeholder="Укажите причину отклонения"
                value={commitEnd}
                onChange={(e) => setCommitEnd(e.target.value)}
              />
            )}
          </div>
        </div>
      </Modal>
      {' '}
      <Modal
        open={editRequestModal}
        okText="Подтвердить"
        okButtonProps={{
          disabled: !editReason?.trim(),
          onClick: handleEditRequest,
          loading: editRequestPending
        }}
        onCancel={() => {
          setEditRequestModal(false);
          setEditReason('');
        }}
      >
        <Space
          style={{
            marginTop: '15px',
            marginBottom: '30px'
          }}
          direction="vertical"
        >
          <Space>
            <Typography>Причина редактирования</Typography>
          </Space>
          <Space>
            <Input
              style={{width: '300px'}}
              placeholder="Введите причину"
              value={editReason}
              onChange={(e) => setEditReason(e.target.value)}
            />
          </Space>
        </Space>
      </Modal>
      {drawerOpen && size === 'mobile' ? (
        <Drawer
          height="100%"
          placement="top"
          open={drawerOpen}
          className={style.Details}
          onClose={() => setDrawerOpen(false)}
          destroyOnClose
        >
          <div className={style.CardHeader}>
            <Typography className={style.CardTitle}>
              <div>
                Согласование №
                {bill.id}
              </div>
              <div
                className={style.CardTitleFrom}
              >
                от
                {' '}
                {dayjs(bill.createdAt).format('DD.MM.YY HH:mm')}
              </div>
            </Typography>
            {getBage()}
          </div>
          <div className={style.CardRows}>
            <div>
              <Steps
                className={style.Steps}
                items={NormalizeItems()}
                direction="vertical"
                labelPlacement="horizontal"
                size="default"
              />
            </div>
            <div className={style.List}>
              {objectList('desktop').map((obj) => (
                <>
                  <Title level={5} className={style.ListTitle}>
                    {obj.title}
                    :
                    {' '}
                  </Title>
                  <div>{getValueBill(obj.key)}</div>
                </>
              ))}
            </div>
          </div>
        </Drawer>
      ) : (
        ''
      )}
    </Card>
  );
}
